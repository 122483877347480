import React, {useState} from 'react';
import jwt_decode from 'jwt-decode';
import ApiClient from './ApiClient';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import API_URL from '../config';
const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');  
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsSubmitting(true);

            let prefferedLanguage = navigator.language || navigator.userLanguage;
            const response = await axios.post(API_URL + 'api/auth/login',{ email, password,prefferedLanguage });
           
                const data = await response.data;
                const token = data.token;
                const decodedToken = jwt_decode(token);

                const isGuide = decodedToken.type;
                // Zapisz token JWT w local storage lub w inny sposób przechowywania sesji
                localStorage.setItem('accessToken', token);       
                const currentDate = new Date(); // Bieżąca data i czas
                const oneHourLater = new Date(currentDate.getTime() + (1 * 60 * 60 * 1000)); // Dodanie jednej godziny (1 * 60 * 60 * 1000 milisekund)

                localStorage.setItem('expirationDate', oneHourLater);       
                localStorage.setItem('fullyVerified', data.fullyVerified);       

                // Zalogowano pomyślnie, przekieruj na inną stronę
                if (window.location.href.indexOf("login") > -1) {
                    if(isGuide === 'True')
                    {
                        window.location.href = `/${lang}/guide/list`;
                        // let isFullyVerified = localStorage.getItem('fullyVerified'); 
                        // if(isFullyVerified === 'true')
                        // {
                        //     window.location.href = '/guide/list';
                        // }else{
                        //     window.location.href = '/guide';

                        // }

                    }else{
                        window.location.href = `/${lang}/`;

                    }
                }else{
                    window.location.reload();
                }
                          
            
        } catch (error) {
            console.error('Error:', error);
            if(error.response && error.response.data.includes("GuideNotVerified"))
            {
                message.error(t("login.guideAccountNotVerifeid"));

            }else if(error.response && error.response.data.includes("Unauthorized"))
            {
                message.error(t("message.incorrectemailorpassword"));

            }else
            if(error.response && error.response.data.includes("AccountLocked"))
            {
                message.error(t("login.userNotFound"));
                
            }
            else
            message.error(t("message.error"));
        }finally {
            // Enable the button after the form submission is complete
            setIsSubmitting(false);
        }
     
    };

    return (
        <form className="form-container" onSubmit={handleSubmit}>
            <h3>{t("login.login")}</h3>
           
            <div>
                <label>{t("login.email")}:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.password")}:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <button type="submit" disabled={isSubmitting}>{isSubmitting ? t("login.inProgres") : t("login.login")} </button>
            <label><a href={`/${lang}/forgot-password`}>{t("login.forgotPassword")}</a></label>
        </form>
    );
};


export default LoginForm;
