import React, {useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import LoginUpperSection from './LoginUpperSection';
import LoginLowerSection from './LoginLowerSection';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    return (
        <Container>
             <Helmet>
                    <meta charSet="utf-8" />  
                    <html lang={lang} />
                    <meta name="description" content={t("homepage.descriptionTag")} />
                    <title>{t('homepage.descriptionTag')}</title>                    
                    <link rel="canonical" href={`https://tourpilot.eu/${lang}/login`} />
                    <link rel="alternate" href="https://tourpilot.eu/pl/login" hreflang="pl" />
                    <link rel="alternate" href="https://tourpilot.eu/en/login" hreflang="en" />
                    <link rel="alternate" href="https://tourpilot.eu/pl/login" hreflang="x-default" />
                </Helmet>

                <Row style={{backgroundColor: 'rgb(51, 51, 51)'}}>
                <Col md={3} className="mx-auto">
                    <LoginForm/>
                </Col>
                <Col md={3} className="mx-auto">
                    <RegistrationForm/>
                </Col>
            </Row>
            <LoginUpperSection/>
            <LoginLowerSection/>
        </Container>
    );
};

export default LoginPage;
