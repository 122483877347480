import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { useTranslation } from 'react-i18next';

const withGuideAccess = (WrappedComponent) => {
    const ComponentWithGuideAuth = () => {
        const navigate = useNavigate();
        const token = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : '';
        const decodedToken = token ? jwt_decode(token) : "";
        const sExpirationDate = localStorage.getItem('expirationDate') ? localStorage.getItem('expirationDate') : '';
        const currentDate = new Date(); // Bieżąca data i czas
        const isGuide = decodedToken ? decodedToken.type.toLowerCase() === "true": undefined;
        const { t, i18n } = useTranslation(); // Access i18n instance
        const lang = i18n.language; // Get the current language
        useEffect(() => {
            if (!token || !sExpirationDate) {
                // Brak tokenu, przekieruj na stronę logowania lub wyświetl komunikat o braku dostępu
                window.location.href = `/${lang}/login`; // Zmień na odpowiednią ścieżkę do strony logowania
            }
            var expirationDate = new Date(sExpirationDate);
            if(expirationDate < currentDate)
            {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('accexpirationDateessToken');
                window.location.href = `/${lang}/login`;

            }
            if (isGuide !== true) {
                // IsGuide nie jest ustawiony na true, wyświetl komunikat o braku dostępu lub przekieruj na odpowiednią stronę
                window.location.href = `/${lang}/login`; // Zmień na odpowiednią ścieżkę do strony z komunikatem o braku dostępu
            }
           
        }, [token, isGuide, navigate]);

        return <WrappedComponent />;
    };

    return ComponentWithGuideAuth;
};

export default withGuideAccess;
