import Home from "./components/Home";
import LoginPage from "./components/Login";
import RegisterGuidePage from "./components/RegisterGuide";
import Confirmation from "./components/Confirmation";
import GuideAddTourForm from "./components/GuideAddTourForm";
import GuideEditTourForm from "./components/GuideEditTourForm";
import RequestPasswordPage from "./components/RequestPasswordPage";
import ResetPasswordPage from "./components/ResetPasswordPage";
import GuideToursList from "./components/GuideToursList";
import ToursList from "./components/ToursList";
import TourPreview from "./components/TourPreview";
import MyAccountGuide from "./components/MyAccountGuide";
import PaymentSuccess from "./components/PaymentSuccess";
import Reservations from "./components/Reservations";
import ReservationOpinion from "./components/ReservationOpinion";
import GuideTourScheduleForm from "./components/GuideTourScheduleForm";
import Regulations from "./components/Regulations";
import GuidePreview from "./components/GuidePreview";
import BlogMain from "./components/Blog/BlogMain";
import AtrakcjeGdansk from "./components/Blog/AtrakcjeGdansk";
import AtrakcjeKrakow from "./components/Blog/AtrakcjeKrakow";
import AtrakcjeWarszawa from "./components/Blog/AtrakcjeWarszawa";
import AtrakcjeWroclaw from "./components/Blog/AtrakcjeWroclaw";
import AtrakcjeMazury from "./components/Blog/AtrakcjeMazury";
import AtrakcjePoznan from "./components/Blog/AtrakcjePoznan";
import AtrakcjeZakopane from "./components/Blog/AtrakcjeZakopane";
import NavMenu from "./components/NavMenu";
import NotFound from "./components/NotFound";

const AppRoutes = [
  {
    index: true,
    path: '/:lang',
    element: <Home />
  },
  {
    index: true,
    path: '/:lang/menu',
    element: <NavMenu />
  },
  {
    path: '/:lang/login',
    element: <LoginPage />
  },
  {
    path: '/:lang/register/guide',
    element: <RegisterGuidePage />
  },
  {
    path: '/confirmation',
    element: <Confirmation />
  },{
    path:"/:lang/guide/add-tour",
    element: <GuideAddTourForm />
  },{
    path:"/:lang/guide/list",
    element: <GuideToursList />
  },  
  {
    path:"/:lang/guide/schedule/:id",
    element: <GuideTourScheduleForm />
  },
  {
    path: '/:lang/forgot-password',
    element: <RequestPasswordPage />
  },
  {
    path: '/:lang/reset-password/:token',
    element: <ResetPasswordPage />
  },{
    path:"/:lang/guide/edit-tour/:id",
    element: <GuideEditTourForm />
  },
  {
    path:"/:lang/attractions/:region",
    element: <ToursList />
  },
  {
    path:"/:lang/guide",
    element: <MyAccountGuide />
  },
  {
    path:"/:lang/tours/:id/:city",
    element: <TourPreview />
  },
  {
    path:"/:lang/payments/success",
    element: <PaymentSuccess />
  },
  {
    path:"/:lang/reservations",
    element: <Reservations />
  },
  {
    path:"/:lang/reservations/:tourDefinitionId/:id/opinion",
    element: <ReservationOpinion />
  }, 
  {
    path:"/:lang/regulations",
    element: <Regulations/>
  }, 
  {
    path:"/:lang/guide/:name",
    element: <GuidePreview />
  },
  {
    path:"/:lang/blog",
    element: <BlogMain />
  },
  {
    path:"/:lang/blog/gdansk-atrakcje",
    element: <AtrakcjeGdansk />
  },
  {
    path:"/:lang/blog/krakow-atrakcje",
    element: <AtrakcjeKrakow />
  },
  {
    path:"/:lang/blog/warszawa-atrakcje",
    element: <AtrakcjeWarszawa />
  },
  {
    path:"/:lang/blog/wroclaw-atrakcje",
    element: <AtrakcjeWroclaw />
  },
  {
    path:"/:lang/blog/zakopane-atrakcje",
    element: <AtrakcjeZakopane />
  },
  {
    path:"/:lang/blog/mazury-atrakcje",
    element: <AtrakcjeMazury />
  },
  {
    path:"/:lang/blog/poznan-atrakcje",
    element: <AtrakcjePoznan />
  },
  
  {
    path: '*',
    element: <NotFound />
  }
];



export default AppRoutes;
