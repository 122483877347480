import React from 'react';
import { Table } from 'antd';
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import './../toursListComponent.css'; // Importujemy plik CSS dla wersji desktopowej
import DisplayTextComponent from "./DisplayTextComponent.js";
import DisplayTextHelper from './DisplayTextHelper.js';

const ToursListComponent = ({ dataSource, initLoading, loadMore }) => {
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language

    const columns = [
        {
            title: '',
            dataIndex: 'mainImage',
            key: 'mainImage',
            width: "20%",
            render: (text, item) => (
                <img
                    width={150}
                    height={150}
                    alt={DisplayTextHelper( {data:item.title} )}
                    src={item.mainImage == null ? '../img/empty.jpg' : `${API_URL}images/tours/${item.id}/thumb/thumb_${item.mainImage}`}
                />
            )
        },
        {
            title: '',
            dataIndex: 'details',
            key: 'details',
            render: (text, item) => (
                <a href={lang+`/tours/${item.id}/${item.region}`} className="tour-link" >
                    <div className="details-container">
                        <div>
                            <div className="tour-title"> <a href={`${lang}/tours/${item.id}/${item.region}`} className="tour-link" rel='dofollow'><DisplayTextComponent data={item.title}/></a></div>                       
                            <div>{item.region}</div>
                        </div>
                    </div>
                </a>
            )
        },
        {
            title: '',
            dataIndex: 'prices',
            key: 'prices',
            width: "30%",
            render: (text, item) => (
                <span dangerouslySetInnerHTML={{ __html: getPrices(item) }} />
            )
        }
    ];

    const getPrices = (item) => {
        if (item.perPerson) {
            try {
                const dataArray = JSON.parse(item.perPersonPriceJson);
                return dataArray.map(row => {
                    const formattedPrice = row.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    return `${translateType(row.name)} (${row.ageFrom}-${row.ageTo}) - ${formattedPrice} ${item.currency}`;
                }).join('<br />')

            } catch (error) {
                console.error('Invalid JSON format', error);
            }

        } else {
            return item.price + " " + item.currency;
        }
    };

   

    const translateType = (value) => {
        if (value === "Adult") {
            return t("priceComponent.adult")
        } else if ("Kid") {
            return t("priceComponent.kid")
        }
    };

    const data = dataSource.map((item, index) => ({
        key: item.id,
        ...item
    }));

    return (
        <Table
            columns={columns}
            dataSource={data}
            loading={initLoading}
            pagination={false}
            className="tours-list"
            onRow={(record) => {
                return {
                    onClick: () => {
                        window.location.href = `${lang}/tours/${record.id}/${record.region}`;
                    }
                };
            }}
        />
    );
};

export default ToursListComponent;
